import $ from "jquery";

$(document).ready(function () {
    $('input[name="pcontact-email"]').change(function () {
        if ($(this).is(":checked")) $("p.email-address").removeClass("hide");
        else $("p.email-address").addClass("hide");
    });

    $(".form-validate").submit(function (event) {
        var errorField = '<span class="prompt-error error">Required</span>';
        var errorField2 = '<span class="prompt-error error">Please select</span>';
        $(".prompt-error").remove();

        var fields = $("p.field.required-field.text", this);
        var valid = true;
        $("p.field.required-field.text input").css({
            border: "",
        });

        $("p.field.required-field.dropdown .select_container").css({
            border: "",
        });
        fields.each(function (index) {
            var obj = $(this);
            // var label = $("label[for='"+$element.attr('id')+"']");
            // alert(label);
            var text = $("label", obj).text();
            text = text.replace("*:", "");

            // alert(text)
            if (obj.hasClass("ignore") && obj.hasClass("hide")) return;

            var inputVal = $("input", obj).val();
            if (inputVal != "") return;

            obj.append(errorField);
            $("input", obj).css({
                border: "1px solid red",
            });
            valid = false;
        });

        var dropdowns = $("p.field.required-field.dropdown");
        dropdowns.each(function (index) {
            var obj = $(this);
            var select = $("select :selected", obj).val();
            if (select !== "") return;

            obj.append(errorField2);
            $(".select_container", obj).css({
                border: "1px solid red",
            });
            valid = false;
        });

        var radios = $("p.field.radio-field.required-field");
        radios.each(function (index) {
            var obj = $(this);
            $("input", obj).css({
                border: "0px solid red",
            });
            var checked = $("input:checked", obj);

            if (checked.length > 0) return;
            $("input", obj).css({
                border: "1px solid red",
            });
            obj.append('<br/><span class="red">Required field</span>');
            valid = false;
        });
        var checkboxes = $("p.checkbox-field.required-field");
        checkboxes.each(function (index) {
            var obj = $(this);
            $("input", obj).css({
                border: "0px solid red",
            });
            var checkedx = $("input:checked", obj);

            if (checkedx.length > 0) return;
            //alert ("xx");
            $(".checkmark", obj).css({
                border: "1px solid red",
            });
            //obj.append('<br/><span class="red">Required field</span>');
            valid = false;
        });
        var dates = $("p.field.date");
        dates.each(function (index) {
            var obj = $(this);
            var value = $("input", obj).val();
            if (value == "" || (value.length == 10 && ValidateDate(value))) return;

            //obj.append('<label class="prompt-error" style="clear:left;">&nbsp;</label><span class="prompt-error error">Date format mm/dd/YYYY</span>');
            //valid = false;
        });

        var zipcodes = $("p.field.zipcode");
        zipcodes.each(function (index) {
            var obj = $("input", this);
            var value = obj.val();
            if (value !== "") {
                value = value.replace(/[^0-9\\.]+/g, "");

                if (value.length !== 5) {
                    valid = false;
                    $(this).append(
                        '<label class="prompt-error" style="clear:left;">&nbsp</label><span class="prompt-error error">Must be 5 digits long.</span>'
                    );
                }

                obj.val(value);
            }
        });

        var passwords = $("p.field.password");
        passwords.each(function (index) {
            var obj = $(this);
            var otherObj = $("p.field." + obj.attr("data-count") + "-retyped");

            var value = $("input", obj).val();
            var retypedValue = $("input", otherObj).val();

            if (value == "" || (value.length >= 5 && retypedValue == value)) return;

            if (value.length < 5)
                obj.append(
                    '<label class="prompt-error" style="clear:left;">&nbsp;</label><span class="prompt-error error">At least 5 characeters long.</span>'
                );
            else if (retypedValue != value)
                otherObj.append(
                    '<label class="prompt-error" style="clear:left;">&nbsp;</label><span class="prompt-error error">Doesn\'t match password.</span>'
                );

            valid = false;
        });

        if ($("input.datepicker.invalid").length > 0) {
            valid = false;
            alert("XOLAIR is not indicated for patients under 12 years of age. Please confirm birth date.");
        }

        if (!valid) event.preventDefault();
    });
    $(".form-validate-user").submit(function (event) {
        var errorField = '<span class="prompt-error error">Please enter';

        $(".prompt-error").remove();

        var fields = $("p.field.required-field.text", this);
        var valid = true;
        $("p.field.required-field.text input").css({
            border: "",
        });

        $("p.field.required-field.dropdown .select_container").css({
            border: "",
        });
        fields.each(function (index) {
            var obj = $(this);
            // var label = $("label[for='"+$element.attr('id')+"']");
            // alert(label);
            var text = $("label", obj).text();
            text = text.replace("*:", "");

            // alert(text)
            if (obj.hasClass("ignore") && obj.hasClass("hide")) return;

            var inputVal = $("input", obj).val();
            if (inputVal != "") return;

            obj.append(errorField + " " + text + "</span>");
            $("input", obj).css({
                border: "1px solid red",
            });
            valid = false;
        });

        if (!valid) event.preventDefault();
    });
    $(".form-validate-search-provider").submit(function (event) {
        var errorField = '<span class="prompt-error error">Please enter';
        var errorField2 = '<span class="prompt-error error">Please select state</span>';
        $(".prompt-error").remove();

        var fields = $(".search-provider p.field.required-field.text");
        var valid = true;
        $(".search-provider p.required-field.text input").css({
            border: "",
        });

        $(".search-provider p.required-field.dropdown .select_container").css({
            border: "",
        });
        fields.each(function (index) {
            var obj = $(this);
            // var label = $("label[for='"+$element.attr('id')+"']");

            var text = $("label", obj).text();
            text = text.replace("*:", "");

            // alert(text)
            if (obj.hasClass("ignore") && obj.hasClass("hide")) return;

            var inputVal = $("input", obj).val();
            if (inputVal != "") return;

            obj.append(errorField + " " + text + "</span>");
            $("input", obj).css({
                border: "1px solid red",
            });
            valid = false;
        });

        var dropdowns = $(".search-provider p.field.required-field.dropdown");
        dropdowns.each(function (index) {
            var obj = $(this);
            var select = $("select :selected", obj).val();
            if (select !== "default") return;

            obj.append(errorField2);
            $(".select_container", obj).css({
                border: "1px solid red",
            });
            valid = false;
        });

        if (!valid) event.preventDefault();
    });

    $(".form-validate-search-provider").submit(function (event) {
        var errorField = '<span class="prompt-error error">Please enter';
        var errorField2 = '<span class="prompt-error error">Please select state</span>';
        $(".prompt-error").remove();

        var fields = $(".search-provider p.field.required-field.text");
        var valid = true;
        $(".search-provider p.required-field.text input").css({
            border: "",
        });

        $(".search-provider p.required-field.dropdown .select_container").css({
            border: "",
        });
        fields.each(function (index) {
            var obj = $(this);
            // var label = $("label[for='"+$element.attr('id')+"']");

            var text = $("label", obj).text();
            text = text.replace("*:", "");

            // alert(text)
            if (obj.hasClass("ignore") && obj.hasClass("hide")) return;

            var inputVal = $("input", obj).val();
            if (inputVal != "") return;

            obj.append(errorField + " " + text + "</span>");
            $("input", obj).css({
                border: "1px solid red",
            });
            valid = false;
        });

        var dropdowns = $(".search-provider p.field.required-field.dropdown");
        dropdowns.each(function (index) {
            var obj = $(this);
            var select = $("select :selected", obj).val();
            if (select !== "default") return;

            obj.append(errorField2);
            $(".select_container", obj).css({
                border: "1px solid red",
            });
            valid = false;
        });

        if (!valid) event.preventDefault();
    });

    $(".form-validate-provider").submit(function (event) {
        var errorField = '<span class="prompt-error error">Please enter';
        var errorField2 = '<span class="prompt-error error">Please select state</span>';
        var errorField3 = '<span class="prompt-error error">Please provide the requested field(s) to continue.</span>';
        $(".prompt-error").remove();

        var fields = $(".form-validate-provider p.field.required-field.text");
        var valid = true;
        $(".form-validate-provider p.required-field.text input").css({
            border: "",
        });

        $(".form-validate-provider p.required-field.dropdown .select_container").css({
            border: "",
        });
        fields.each(function (index) {
            var obj = $(this);
            // var label = $("label[for='"+$element.attr('id')+"']");

            var text = $("label", obj).text();
            text = text.replace("*:", "");

            // alert(text)
            if (obj.hasClass("ignore") && obj.hasClass("hide")) return;

            var inputVal = $("input", obj).val();
            if (inputVal != "") return;

            obj.append(errorField + " " + text + "</span>");
            $("input", obj).css({
                border: "1px solid red",
            });
            valid = false;
        });

        var dropdowns = $(".form-validate-provider p.field.required-field.dropdown");
        dropdowns.each(function (index) {
            var obj = $(this);
            var select = $("select :selected", obj).val();
            if (select !== "default") return;

            obj.append(errorField2);
            $(".select_container", obj).css({
                border: "1px solid red",
            });
            valid = false;
        });

        if (valid == false) $(".form-error").html(errorField3);

        if (!valid) event.preventDefault();
    });
    $(".form-validate-search-care").submit(function (event) {
        var errorField = '<span class="prompt-error error">Please enter';
        var errorField2 = '<span class="prompt-error error">Please select state</span>';

        $(".prompt-error").remove();

        var fields = $(".search-care p.field.required-field.text");
        var valid = true;
        $(".search-care p.field.required-field.text input").css({
            border: "",
        });

        $(".search-care p.field.required-field.dropdown .select_container").css({
            border: "",
        });
        fields.each(function (index) {
            var obj = $(this);
            // var label = $("label[for='"+$element.attr('id')+"']");
            // alert(label);
            var text = $("label", obj).text();
            text = text.replace("*:", "");

            // alert(text)
            if (obj.hasClass("ignore") && obj.hasClass("hide")) return;

            var inputVal = $("input", obj).val();
            if (inputVal != "") return;

            obj.append(errorField + " " + text + "</span>");
            $("input", obj).css({
                border: "1px solid red",
            });
            valid = false;
        });

        var dropdowns = $(".search-care p.field.required-field.dropdown");
        dropdowns.each(function (index) {
            var obj = $(this);
            var select = $("select :selected", obj).val();
            if (select !== "default") return;

            obj.append(errorField2);
            $(".select_container", obj).css({
                border: "1px solid red",
            });
            valid = false;
        });

        if (!valid) event.preventDefault();
    });

    $(".form-validate-care").submit(function (event) {
        var errorField = '<span class="prompt-error error">Please enter';
        var errorField2 = '<span class="prompt-error error">Please select state</span>';
        var errorField3 = '<span class="prompt-error error">Please provide the requested field(s) to continue.</span>';
        $(".prompt-error").remove();

        var fields = $(".form-validate-care p.field.required-field.text");
        var valid = true;
        $(".form-validate-care p.field.required-field.text input").css({
            border: "",
        });

        $(".form-validate-care p.field.required-field.dropdown .select_container").css({
            border: "",
        });
        fields.each(function (index) {
            var obj = $(this);
            // var label = $("label[for='"+$element.attr('id')+"']");
            // alert(label);
            var text = $("label", obj).text();
            text = text.replace("*:", "");

            // alert(text)
            if (obj.hasClass("ignore") && obj.hasClass("hide")) return;

            var inputVal = $("input", obj).val();
            if (inputVal != "") return;

            obj.append(errorField + " " + text + "</span>");
            $("input", obj).css({
                border: "1px solid red",
            });
            valid = false;
        });

        var dropdowns = $(".form-validate-care p.field.required-field.dropdown");
        dropdowns.each(function (index) {
            var obj = $(this);
            var select = $("select :selected", obj).val();
            if (select !== "default") return;

            obj.append(errorField2);
            $(".select_container", obj).css({
                border: "1px solid red",
            });
            valid = false;
        });

        if (valid == false) $(".form-error").html(errorField3);

        if (!valid) event.preventDefault();
    });
    $(".form-validate-attestation").submit(function (event) {
        var errorField = '<span class="prompt-error error">Please enter';
        var errorField2 = '<span class="prompt-error2 error">Please pick a section to complete.</span>';

        $(".prompt-error").remove();
        $(".prompt-error2").remove();

        var fields = $("p.field.required-field.text", this);
        var fields2 = $("p.mandatory1.field.text", this);
        var fields3 = $("p.mandatory.field.text", this);

        var valid = true;
        var valid2 = false;
        $("p.field.required-field.text input").css({
            border: "",
        });
        $("p.mandatory1.field.text input").css({
            border: "",
        });
        $("p.mandatory.field.text input").css({
            border: "",
        });

        fields.each(function (index) {
            var obj = $(this);
            // var label = $("label[for='"+$element.attr('id')+"']");
            // alert(label);
            var text = $("label", obj).text();
            text = text.replace("*:", "");

            // alert(text)
            if (obj.hasClass("ignore") && obj.hasClass("hide")) return;

            var inputVal = $("input", obj).val();
            if (inputVal != "") return;

            obj.append(errorField + " " + text + "</span>");
            $("input", obj).css({
                border: "1px solid red",
            });
            valid = false;
        });

        fields2.each(function (index) {
            var obj = $(this);
            // var label = $("label[for='"+$element.attr('id')+"']");
            // alert(label);
            var text = $("label", obj).text();
            text = text.replace("*:", "");

            var inputVal = $("input", obj).val();
            if (inputVal != "") {
                valid2 = true;
            }
        });

        fields3.each(function (index) {
            var obj = $(this);
            // var label = $("label[for='"+$element.attr('id')+"']");
            // alert(label);
            var text = $("label", obj).text();
            text = text.replace("*:", "");

            // alert(text)

            var inputVal = $("input", obj).val();
            if (inputVal != "") {
                valid2 = true;
            }
        });

        if (!valid) {
            event.preventDefault();
        }

        if (!valid2) {
            event.preventDefault();
            $("p.error").append(errorField2);
        }
    });
    $(".form-eligibility-patient").submit(function (event) {
        var errorField = '<span class="prompt-error error">Please enter date of birth.</span>';
        var errorField2 = '<span class="prompt-error error">Please select state</span>';
        $(".prompt-error").remove();
        $(".error").remove();
        $(".text-error").remove();

        var fields = $("p.field.required-field.text", this);
        var valid = true;
        $("p.field.required-field.text input").css({
            border: "",
        });
        $("p.required-field.dropdown .select_container").css({
            border: "",
        });
        fields.each(function (index) {
            var obj = $(this);
            // var label = $("label[for='"+$element.attr('id')+"']");
            // alert(label);
            var text = $("label", obj).text();
            text = text.replace("*:", "");
            // alert(text)
            if (obj.hasClass("ignore") && obj.hasClass("hide")) return;

            var inputVal = $("input", obj).val();
            if (inputVal != "") return;

            $(".dob").append('<span class="error">Please enter date of birth.</span>');
            $("input", obj).css({
                border: "1px solid red",
            });
            valid = false;
        });
        var data = $("p.dob input").val();
        //alert(data);
        var date = new Date(data);
        var currentDate = new Date();

        if (currentDate.getFullYear() - date.getFullYear() < 18) valid = false;
        else if (currentDate.getFullYear() - date.getFullYear() == 18) {
            if (currentDate.getMonth() < date.getMonth()) valid = false;
            else if (currentDate.getMonth() == date.getMonth()) {
                if (currentDate.getDate() < date.getDate()) valid = false;
            }
        }
        var dropdowns = $("p.required-field.dropdown");
        dropdowns.each(function (index) {
            var obj = $(this);
            var select = $("select :selected", obj).val();
            if (select !== "default") return;

            //obj.append(errorField2);
            $(".dropdown").append('<p class="text-error red">Please select state.</p>');
            $(".select_container", obj).css({
                border: "1px solid red",
            });
            valid = false;
        });

        if (!valid) {
            $(this).addClass("invalid");
            if (!$(".dob span.error").length) {
                $(".dob").append(
                    '<span class="error">Patients under 18 years of age must have a legally authorized person enroll on their behalf.</span>'
                );
            }
        } else {
            $(this).removeClass("invalid");
            $(".dob .error").remove();
        }

        var errorField2 = '<p class="text-error"><span class="prompt-error error">Please select.</span></p>';
        $(".prompt-error").remove();

        var radios = $(".eligibility td.radios");
        $(".error").append("");
        radios.each(function (index) {
            var obj = $(this);

            var checked = $("input:checked", obj);
            //alert(checked.length);
            if (checked.length > 0) return;

            //$('.error').append(errorField);
            obj.append(errorField2);
            valid = false;
        });

        if (!valid) event.preventDefault();
    });
    $(".form-eligibility-lap").submit(function (event) {
        var errorField = '<span class="prompt-error error">Please enter date of birth.</span>';
        $(".prompt-error").remove();
        $(".error").remove();
        $(".text-error").remove();

        var fields = $("p.field.required-field.text", this);
        var valid = true;
        $("p.field.required-field.text input").css({
            border: "",
        });
        $("p.required-field.dropdown .select_container").css({
            border: "",
        });

        fields.each(function (index) {
            var obj = $(this);
            // var label = $("label[for='"+$element.attr('id')+"']");
            // alert(label);
            var text = $("label", obj).text();
            text = text.replace("*:", "");
            // alert(text)
            if (obj.hasClass("ignore") && obj.hasClass("hide")) return;

            var inputVal = $("input", obj).val();
            if (inputVal != "") return;

            $(".dob").append('<span class="error">Please enter date of birth.</span>');
            $("input", obj).css({
                border: "1px solid red",
            });

            valid = false;
        });

        var dropdowns = $("p.required-field.dropdown");
        dropdowns.each(function (index) {
            var obj = $(this);
            var select = $("select :selected", obj).val();
            if (select !== "default") return;

            //obj.append(errorField2);
            $(".dropdown").append('<p class="text-error red">Please select state.</p>');
            $(".select_container", obj).css({
                border: "1px solid red",
            });
            valid = false;
        });

        var errorField2 = '<p class="text-error"><span class="prompt-error error">Please select.</span></p>';
        $(".prompt-error").remove();

        var radios = $(".eligibility td.radios");
        $(".error").append("");
        radios.each(function (index) {
            var obj = $(this);

            var checked = $("input:checked", obj);
            //alert(checked.length);
            if (checked.length > 0) return;

            //$('.error').append(errorField);
            obj.append(errorField2);
            valid = false;
        });

        if (!valid) event.preventDefault();
    });
    $(".enroll").submit(function (event) {
        var errorField = '<p><span class="prompt-error error">Please select at least one option below.</span></p>';
        $(".prompt-error").remove();
        var valid = true;
        var radios = $(".no-box");
        $(".error").append("");
        radios.each(function (index) {
            var obj = $(this);

            var checked = $("input:checked", obj);
            //alert(checked.length);
            if (checked.length > 0) return;

            $(".error").append(errorField);
            valid = false;
        });

        //alert(valid);
        if (!valid) event.preventDefault();
    });
    function submitForm() {
        // submits form
        document.getElementById("form-review").submit();
    }

    $(".form-review").submit(function (event) {
        //event.preventDefault();
        setTimeout(10000); // set timout
    });

    $(".form-validate-password").submit(function (event) {
        var errorField = '<span class="prompt-error error">Please enter';
        $(".prompt-error").remove();

        var fields = $("p.field.required-field.password", this);
        var valid = true;
        valid = true;
        $("p.field.required-field.password input").css({
            border: "",
        });
        fields.each(function (index) {
            var obj = $(this);
            // var label = $("label[for='"+$element.attr('id')+"']");
            // alert(label);
            var text = $("label", obj).text();
            text = text.replace("*:", "");

            // alert(text)
            if (obj.hasClass("ignore") && obj.hasClass("hide")) return;

            var inputVal = $("input", obj).val();
            if (inputVal != "") return;

            obj.append(errorField + " " + text + "</span>");
            $("input", obj).css({
                border: "1px solid red",
            });
            valid = false;
        });

        var passwords = $("p.field.password");
        passwords.each(function (index) {
            var obj = $(this);
            var otherObj = $("p.field." + obj.attr("data-count") + "-retyped");
            var text = $("label", obj).text();

            text = text.replace("*:", "");
            text = text.replace("*", "");
            var value = $("input", obj).val();
            var retypedValue = $("input", otherObj).val();

            if (value == "" || value.length >= 12 || text == "Current Password") return;

            if (value.length < 12) obj.append('<span class="prompt-error error">' + text + " must be at least 12 characters long.</span>");
            else if (retypedValue != value) otherObj.append('<span class="prompt-error error">Doesn\'t match password.</span>');

            valid = false;
        });
        var p2 = $("#pass_2").val();
        var p3 = $("#pass_3").val();
        if (p2 != p3) {
            $("p.field.password.password-retype").append(
                '<span class="prompt-error error">New Password and Confirm New Password do not match.</span>'
            );
            valid = false;
        }

        //alert(valid);
        if (!valid) event.preventDefault();
    });
    $(".form-validate-create-password").submit(function (event) {
        var errorField = '<span class="prompt-error error">Please enter';
        $(".prompt-error").remove();

        var fields = $("p.field.required-field", this);
        var valid = true;
        valid = true;
        $("p.field.required-field input").css({
            border: "",
        });
        fields.each(function (index) {
            var obj = $(this);
            // var label = $("label[for='"+$element.attr('id')+"']");
            // alert(label);
            var text = $("label", obj).text();
            text = text.replace("*:", "");

            // alert(text)
            if (obj.hasClass("ignore") && obj.hasClass("hide")) return;

            var inputVal = $("input", obj).val();
            if (inputVal != "") return;

            obj.append(errorField + " " + text + "</span>");
            $("input", obj).css({
                border: "1px solid red",
            });
            valid = false;
        });

        var passwords = $("p.field.password");
        passwords.each(function (index) {
            var obj = $(this);
            var otherObj = $("p.field." + obj.attr("data-count") + "-retyped");
            var text = $("label", obj).text();

            text = text.replace("*:", "");
            var value = $("input", obj).val();
            var retypedValue = $("input", otherObj).val();

            if (value == "" || value.length >= 12) return;

            if (value.length < 12) obj.append('<span class="prompt-error error">' + text + " must be at least 12 characters long.</span>");
            else if (retypedValue != value) otherObj.append('<span class="prompt-error error">Doesn\'t match password.</span>');

            valid = false;
        });
        var p2 = $("#pass_2").val();
        var p3 = $("#pass_3").val();
        if (p2 != p3) {
            $("p.field.password.password-retype").append(
                '<span class="prompt-error error">New Password and Confirm New Password do not match.</span>'
            );
            valid = false;
        }

        //alert(valid);
        if (!valid) event.preventDefault();
    });
});

function ValidateDate(input) {
    var regex = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
    return regex.test(input);
}
$(document).ready(function () {
    var errorField = '<span class="prompt-error error">Required';
    var errorField2 = '<span class="prompt-error error">Please enter ';
    $('input[type="text"],input[type="password"]').on("click", function () {
        $(this).prev("label").addClass("smaller");
        $(this).prev("label").removeClass("normal");
        $(this).prev("label").addClass("blue");
    });
    $('.refnum input[type="text"]').on("click", function () {
        $(this).attr("placeholder", "");
    });
    $('input[type="text"],input[type="password"]').on("focusout", function () {
        $(this).prev("label").removeClass("blue");
        if ($(this).val() === "") {
            $(this).prev("label").removeClass("smaller");
            $(this).prev("label").addClass("normal");
        } else {
            $(this).prev("label").removeClass("normal");
            $(this).prev("label").addClass("smaller");
            $(this).next("span").remove();
        }
    });

    $('p.required-field input[type="text"],p.required-field input[type="password"]').on("focusout", function () {
        //phone custom 7/1 email custom 9/27
        if ($(this).val() === "" && !$(this).hasClass("phone_2") && !$(this).hasClass("email_2")) {
            $(this).next("span").remove();
            var text = $(this).prev("label").text();
            text = text.replace("*", "");
            var classForm = $("form").attr("class");
            //alert (classForm);
            if (classForm === "form-validate apply-step-two-form") {
                $(this).after(errorField + "</span>");
            } else {
                $(this).after(errorField2 + " " + text + ".</span>");
            }
            $(this).css({ border: "2px solid red" });
            $(this).prev("label").addClass("red");
        } else {
            if (!$(this).hasClass("phone_2") && !$(this).hasClass("email_2")) {
                $(this).prev("label").removeClass("red");
                $(this).next("span").remove();
                $(this).css({ border: "1px solid #d8d8d8" });
                $(this).prev("label .inner-label").css({ color: "rgba(0,0,0,.6)" });
            }
        }
    });

    $('input[type="text"],input[type="password"]').each(function () {
        if ($(this).val() === "") {
            $(this).prev("label").removeClass("smaller");
            $(this).prev("label").addClass("normal");
        } else {
            $(this).prev("label").removeClass("normal");
            $(this).prev("label").addClass("smaller");
        }
    });

    $(".select_container").on("click", function () {
        $(this).prev("label").addClass("smaller");
        $(this).prev("label").removeClass("normal");
    });
    $(".select_container").on("focusout", function () {
        var obj = $(this);
        var select = $("select :selected", obj).val();
        if (select === "") {
            $(this).prev("label").removeClass("smaller");
            $(this).prev("label").addClass("normal");
        } else {
            $(this).prev("label").removeClass("normal");
            $(this).prev("label").addClass("smaller");
        }
    });
    $("p.required-field .select_container").on("focusout", function () {
        var obj = $(this);
        var select = $("select :selected", obj).val();
        if (select === "") {
            $(this).next("span").remove();
            $(this).after(errorField);
            $(this).css({ border: "2px solid red" });
        } else {
            $(this).next("span").remove();
            $(this).css({ border: "1px solid #d8d8d8" });
        }
    });

    $(".select_container").each(function () {
        var obj = $(this);
        var select = $("select :selected", obj).val();
        if (select === "") {
            $(this).prev("label").removeClass("smaller");
            $(this).prev("label").addClass("normal");
        } else {
            $(this).prev("label").removeClass("normal");
            $(this).prev("label").addClass("smaller");
        }
    });
});
$(".datepicker").datepicker({
    onSelect: function (date) {
        $(this).prev("label").addClass("smaller");
        $(this).prev("label").removeClass("normal");
        $(this).prev("label").addClass("blue");
        $(this).css({ border: "1px solid #d8d8d8" });
    },
});
