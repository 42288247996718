import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLazyQuery, gql, useQuery } from "@apollo/client";
import { ADD_OFFICE_USER, MANAGE_USERS_LIST } from "../../graphql/queries/Providers";

import axios from "axios";

export default function AddOfficeUser() {
    const provider_id = parseInt(window.localStorage.getItem("providerID"));
    const [first_name, setfirstname] = useState("");
    const [last_name, setlastname] = useState("");
    const [email, setemail] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(true);

    const [usernameFocused, setUsernameFocused] = useState(false);
    const [userAdded, setUserAdded] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const [addUser, { called, error, data }] = useLazyQuery(ADD_OFFICE_USER, {
        variables: { provider_id, first_name, last_name, email },
    });
    let navigate = useNavigate();
    function goBack() {
        let path = `../manage-users`;
        navigate(path);
    }

    async function addOfficeUser(e) {
        e.preventDefault();
        checkEmail();
        // console.log(isValidEmail);
        if (isValidEmail) {
            await addUser();

            // if (userAdded)
            goBack();
        } else {
            // console.log("invalid email:" + email);
        }
    }

    useEffect(() => {
        if (userAdded) goBack();
    }, [userAdded]);

    useEffect(() => {
        if (data) {
            if (data?.provadminadd != null) {
                requestPassword();
            }
        } else {
            // setUserAdded(false);
        }
    }, [data]);

    function updateEmail(e) {
        let trimmed_email = e.target.value.trim();
        if (trimmed_email.length > 0) {
            //is not blank, since optional
            if (trimmed_email.toLowerCase().match(/^\S+@\S+\.\S+$/)) {
                console.log("valid email"); //remove in prod
                setIsValidEmail(true);
            } else {
                console.log("invalid email"); //remove in prod
                setIsValidEmail(false);
            }
        } else {
            //blank email is valid (optional)
            setIsValidEmail(false);
        }
        setemail(trimmed_email);
        // setemail(e.target.value);
        setUsernameFocused(true);

        // if (users_list?.data?.providerusersearch) {
        //     console.log(users_list?.data?.providerusersearch);
        // }
    }

    function checkEmail() {
        if (email.length > 0) {
            if (email.toLowerCase().match(/^\S+@\S+\.\S+$/)) {
                setIsValidEmail(true);
            } else {
                setIsValidEmail(false);
            }
        } else {
            //blank email is valid (optional)
            setIsValidEmail(false);
        }
    }

    /**
     * From PasswordRequest.js
     *
     */
    function requestPassword() {
        // e.preventDefault();
        if (email != "") {
            let apiPayload = JSON.stringify({
                login_id: email,
            });
            const headers = { "Content-Type": "application/json;charset=UTF-8", "Access-Control-Allow-Origin": "*" };

            axios
                .post(`https://xremail.netswitchcard.com/request-reset/`, apiPayload, { headers: headers })
                .then((res) => {
                    // console.log(res);
                    // console.log(res.status);
                    if (res.status == "200") {
                        // let path = `../password-request-success`;
                        // console.log("Email sent");
                        setUserAdded(true);
                        return;
                        // navigate(path);
                    }
                    // if (res.status == "204") {

                    //     console.log("Bad email");
                    //     setErrorMsg("Invalid Email");
                    //     setUserAdded(false);
                    //     return;

                    // }
                    //  else {
                    //     setUserAdded(false);
                    // }
                })
                .catch((error) => {
                    // setUserAdded(false);
                    // console.log(error);
                });
        }
    }
    return (
        <div className="container" id="content" style={{ fontSize: "1em" }}>
            <div id="container">
                <div id="hcp_post_content">
                    <h2 style={{ fontSize: "1.25em", lineHeight: "1.49em" }}>Add Office User</h2>
                    <div className="section shadow-box">
                        <form onSubmit={(e) => addOfficeUser(e)} className="form-validate" acceptCharset="utf-8">
                            <h2 style={{ fontSize: "1.25em", lineHeight: "1.49em" }}>User Details</h2>
                            <div className="col-sm-12 col-md-12 col-lg-6">
                                <p className="field required-field text">
                                    <label for="first_name" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        <span className="inner-label">
                                            First name<span className="required">*</span>:
                                        </span>
                                    </label>
                                    <input
                                        type="text"
                                        name="first_name_enroller"
                                        value={first_name}
                                        onChange={(e) => setfirstname(e.target.value)}
                                    />
                                </p>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-6">
                                <p className="field required-field text">
                                    <label for="last_name" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        <span className="inner-label">
                                            Last name<span className="required">*</span>:
                                        </span>
                                    </label>
                                    <input
                                        type="text"
                                        name="last_name_enroller"
                                        value={last_name}
                                        onChange={(e) => setlastname(e.target.value)}
                                    />
                                </p>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-6">
                                <p className="field required-field text">
                                    <label for="email" style={{ fontSize: "1em", lineHeight: "1.49em" }}>
                                        <span className="inner-label">
                                            Username<span className="required">*</span>:
                                        </span>
                                    </label>
                                    <input readOnly type="text" name="address_1_enroller" value={email} />
                                </p>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-6">
                                <p className="field required-field text">
                                    <label
                                        htmlFor="email"
                                        className={`email_2 smaller ${!isValidEmail ? "red" : ""}`}
                                        style={{ fontSize: "1em", lineHeight: "1.49em" }}
                                    >
                                        <span className="inner-label">
                                            Email Address<span className="required">*</span>:
                                        </span>
                                    </label>
                                    <input
                                        type="text"
                                        name="address_2_enroller"
                                        value={email}
                                        onChange={updateEmail}
                                        onBlur={updateEmail}
                                        style={isValidEmail ? {} : { border: "2px solid red" }}
                                        className={`email_2 ${!isValidEmail ? "red" : ""}`}
                                    />
                                    {!isValidEmail && (
                                        <p
                                            class="error"
                                            style={{ fontSize: "13px", padding: 0, margin: 0, display: "inline", marginBottom: 0 }}
                                        >
                                            Please enter a valid email.
                                        </p>
                                    )}
                                </p>
                            </div>
                            <p>
                                <button type="submit" disabled={!isValidEmail}>
                                    Add
                                </button>
                                <button type="reset" onClick={goBack}>
                                    Cancel
                                </button>
                            </p>
                            {errorMsg != "" && !userAdded ? (
                                <span class="error">
                                    <p>{errorMsg}</p>{" "}
                                </span>
                            ) : (
                                <></>
                            )}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
